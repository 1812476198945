import React, { useState, useEffect } from 'react';
import { useCookies } from 'react-cookie';
import { useHistory } from "react-router-dom";
import { useDispatch } from 'react-redux';
import classNames from 'classnames';
import queryString from 'query-string';
import { Icon } from '../AmelieUI';
import Utils from '../../utils/utils';
import { isProduction } from '../../constants';
import AmeliePSLCForm from '../AmeliePSLCForm/AmeliePSLCForm';
import AmeliePSLCSocialLogin from '../AmeliePSLCSocialLogin/AmeliePSLCSocialLogin';
import AmelieDisclaimer from 'components/AmelieDisclaimer/AmelieDisclaimer';

import './AmeliePSLCModal.scss';

const PLC_HIDDEN = 0;
const PLC_SHOW_FORM = 1;
const PLC_SHOW_CONFIRMATION = 2;
const PLC_SHOW_ERROR = 3;

const PLC_COOKIE = 'predefinedSearchModalDismissed';
const PLC_COOKIE_EXPIRY = 7; // Expiry in days after PLC is submitted

const AmeliePSLCModal = () => {
  const [cookies, setCookie] = useCookies();
  const history = useHistory();
  const dispatch = useDispatch();
  const sourceUrl = cookies.fromPredefinedSearch ? decodeURIComponent(cookies.fromPredefinedSearch) : null; // Carmen does cookies.set

  const [visibleState, setVisibleState] = useState(PLC_HIDDEN);
  const [loading, setLoading] = useState(false);
  const [isSocialLogin, setIsSocialLogin] = useState(false);

  const promptedLeadCaptureDismissible = Boolean(Utils.getDefaultConfig('promptedLeadCaptureDismissible'));
  const promptedLeadCaptureEnabled = Utils.getDefaultConfig('promptedLeadCaptureEnabled');
  const promptedLeadCaptureDelay = Utils.getDefaultConfig('promptedLeadCaptureDelay');
  const promptedLeadCapturePages = Utils.getDefaultConfig('promptedLeadCapturePages');

  const getSocialLoginToken = () => {
    const parsed = queryString.parse(window.location.search);
    return (parsed.actionFrom === 'pslc' && parsed.token) ? parsed.token : null;
  };

  const isPredefinedSearch = (function () {
    const referrerUrl = decodeURIComponent(document.referrer);
    if (sourceUrl && sourceUrl === referrerUrl) {
      return true;
    }
    if ((referrerUrl.replace('?return_to=carmen', '') === window.location.href)) {
      return true;
    }
    return false;
  })();

  if (!isProduction()) {
    console.log('referrerUrl', decodeURIComponent(document.referrer));
    console.log('isPredefinedSearch', isPredefinedSearch);
  }

  useEffect(() => {
    if (window._debugPSLCState !== undefined) {
      setVisibleState(Number(window._debugPSLCState));
    }

    (async () => {
      if (getSocialLoginToken()) {
        try {
          const data = {
            token: getSocialLoginToken(),
            listing_url: `${window.location.origin}${window.location.pathname}`,
            predefined_search_url: sourceUrl,
          };

          await dispatch.leadCapture.sendPLCLead({ sourceUrl, isPredefinedSearch, data });
          setDismissedCookie(PLC_COOKIE_EXPIRY);
          setIsSocialLogin(true);
          setVisibleState(PLC_SHOW_CONFIRMATION);
          history.replace(window.location.pathname); // clear token from url
        } catch (e) {
          setVisibleState(PLC_SHOW_ERROR);
        }
        return;
      }

      setTimeout(() => {
        if (!promptedLeadCaptureEnabled) {
          return;
        }

        if (cookies[PLC_COOKIE]) {
          return;
        }

        if (!isPredefinedSearch && promptedLeadCapturePages === 'search') {
          return;
        }

        if (visibleState !== PLC_HIDDEN) {
          return;
        }

        setVisibleState(PLC_SHOW_FORM);
      }, Number(promptedLeadCaptureDelay) * 1000);
    })();
  }, [history, dispatch]);

  /**
   * Set cookie to dismiss the modal for a number of days.
   * Default is 1 day.
   *
   * @param {number} days
   */
  const setDismissedCookie = (days = 1) => {
    const expiryDate = new Date();
    expiryDate.setDate(expiryDate.getDate() + days);
    setCookie(PLC_COOKIE, true, { path: '/', expires: expiryDate });
  };

  const handleCloseClick = () => {
    setDismissedCookie();
    setVisibleState(PLC_HIDDEN);
  };

  const handleFormSubmit = async (values) => {
    setLoading(true);
    try {
      const data = {
        ...values,
        listing_url: `${window.location.origin}${window.location.pathname}`,
        predefined_search_url: sourceUrl,
      };

      await dispatch.leadCapture.sendPLCLead({ sourceUrl, isPredefinedSearch, data });
      setDismissedCookie(PLC_COOKIE_EXPIRY);
      setLoading(false);
      setVisibleState(PLC_SHOW_CONFIRMATION);
    } catch (e) {
      setLoading(false);
      setVisibleState(PLC_SHOW_ERROR);
    }
  };

  function ConfirmationContent({ onClose, isSocialLogin }) {
    return (
      <div className="amelie-pslc-wrapper">
        <div className="amelie-pslc-confirmation">
          <Icon type="close" className="amelie-pslc-confirmation-close" onClick={onClose} />
          <h2 className="title">Thank you for your interest!</h2>
          {isSocialLogin && (
            <div className="success-banner">
              <Icon type="check" className="icon" />
              <p>Your login was successful and your contact details were received!</p>
            </div>
          )}
          <p>We&apos;ll be in touch shortly. In the meantime, feel free to close this message to view the full property details and explore more listings.</p>
        </div>
      </div>
    );
  }

  function ErrorContent({ onClose }) {
    return (
      <div className="amelie-pslc-wrapper">
        <div className="amelie-pslc-confirmation">
          <Icon type="close" className="amelie-pslc-confirmation-close" onClick={onClose} />
          <h2 className="title">Sorry</h2>
          <p>We were unable to save your information at this time, please try again later.</p>
        </div>
      </div>
    );
  }

  function FormContent({ onClose, promptedLeadCaptureDismissible, onSubmit, isSaving, isPredefinedSearch }) {
    return (
      <div className="amelie-pslc-wrapper">
        <div className={classNames('amelie-pslc', { dismissible: promptedLeadCaptureDismissible })}>
          { promptedLeadCaptureDismissible && (
            <Icon type="close" className="amelie-pslc-close" onClick={onClose} />
          ) }
          <div className="amelie-pslc-body">
            <h2 className="title">Ready to learn more about this listing?</h2>
            <p className="subtitle">Please enter your info below to connect.</p>
            <AmeliePSLCForm onSubmit={onSubmit} isSaving={isSaving} />
            <AmeliePSLCSocialLogin isPredefinedSearch={isPredefinedSearch} />
            <AmelieDisclaimer />
          </div>
        </div>
      </div>
    );
  }

  switch (visibleState) {
    case PLC_SHOW_CONFIRMATION:
      return <ConfirmationContent onClose={handleCloseClick} isSocialLogin={isSocialLogin} />;
    case PLC_SHOW_ERROR:
      return <ErrorContent onClose={handleCloseClick} />;
    case PLC_SHOW_FORM:
      return (
        <FormContent
          onClose={handleCloseClick}
          promptedLeadCaptureDismissible={promptedLeadCaptureDismissible}
          onSubmit={handleFormSubmit}
          isSaving={loading}
          isPredefinedSearch={isPredefinedSearch}
        />
      );
    default:
      return null;
  }
}

export default AmeliePSLCModal;
